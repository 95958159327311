<script setup lang="ts">
import type { KippieLocation } from "@shopware-pwa/types";

const props = defineProps<{
	closeDrawer: () => void;
}>();

const emit = defineEmits<{
	(_e: "selection", _location: KippieLocation): void;
}>();

const isSettingPickupLocation = ref(false);
const { updateExtension } = useShopwareCart();
const { onPickupLocationSelected } = useGtagEvents();

const setPickLocation = async (location: KippieLocation) => {
	isSettingPickupLocation.value = true;

	await updateExtension({
		pickupLocationId: location.id,
		deliveryTimeSlot: null
	});

	emit("selection", location);
	props.closeDrawer();

	onPickupLocationSelected(location);

	isSettingPickupLocation.value = false;
};
</script>

<template>
	<StoreLocationSearch only-pickup-locations>
		<template #store-action="{ location }">
			<KippieButton size="small" block @click="setPickLocation(location)" :disabled="isSettingPickupLocation">
				Hier afhalen
			</KippieButton>
		</template>
	</StoreLocationSearch>
</template>
